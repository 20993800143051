import metrics from '../../styles/metrics'
export default {
  container: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: metrics.screenHeight
  },
  title: {
    fontSize: 32,
    textAlign: 'center',
    color: '#ffffff',
    fontWeight: '600',
    marginBottom: 30,
    marginTop: 30
  },
  itemWrapper: {
    display: 'flex',
    backgroundColor: '#CE7EAA',
    borderRadius: 8,
    overflow: 'hidden',
    elevation: 4
  },
  listItem: {
    borderRadius: 8,
    overflow: 'hidden',
    elevation: 4,
    marginBottom: 10
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    paddingBottom: 10,
  },
  itemTitle: {
    color: '#ffffff',
    fontSize: 22,
    fontWeight: '600',
    marginBottom: 5
  },
  itemText: {
    color: '#ffffff',
    fontSize: 13
  },
  listItemTitle: {
    fontSize: 22,
    fontWeight: '600',
    marginBottom: 5
  },
  listItemText: {
    fontSize: 13
  },
  itemImage: {
    flex: 1,
    position: 'relative',
    overflow: 'hidden',
    height: '100%',
  },
  itemImg: {
    position: 'absolute',
    height: '100%',
    width: 'auto',
    left: '50%',
    transform: 'translate(-50%, 0%)'
  },
  itemDescription: {
    padding: 20,
    flex: 1,
    justifyContent: 'center'
  },
  listContainer: {
    paddingHorizontal: 20,
    alignContent: 'center',
    width: metrics.screenWidth
  },
  bannerWrapper: {

  },
  banner: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 20,
    marginTop: 20,
    marginBottom: 20,
    overflow: 'hidden',
    backgroundColor: '#CE7EAA',
    borderRadius: 8,
    elevation: 12,
    height: 200
  },
  bannerTitle: {
    color: '#ffffff',
    fontSize: 24,
    fontWeight: '600',
    marginBottom: 5
  },
  bannerText: {
    color: '#ffffff',
    fontSize: 16
  },
  bannerImage: {
    width: metrics.designRatio * 150,
    height: metrics.designRatio * 150
  },
  bannerDescription: {
    padding: 20,
    width: metrics.designRatio * 200,
  },
  loadingImage: {
    height: 'auto',
    width: '100%',
    maxWidth: 600
  },
  itemButton: {
    width: metrics.designRatio * 100,
    height: metrics.designRatio * 33,
    borderRadius: 18,
    overflow: 'hidden',
    marginRight: 10,
    backgroundColor: '#40a44e'
  },
  itemLine: {
    height: 1,
    backgroundColor: '#dfdcdc',
    elevation: 30,
    marginHorizontal: 20
  },
  listContent: {
    padding: '10px 20px'
  },
  details: {
    backgroundColor: '#ffffff',
    paddingTop: 0,
    paddingBottom: 10,
    textAlign: 'center'
  },
  description: {
    fontSize: 16,
    color: '#A65381',
    fontWeight: '400',
    textAlign: 'center',
    paddingBottom: 10,
    paddingHorizontal: 30
  },
  whiteWaveBg: {
    maxHeight: 80,
    width: '100%'
  },
  whiteWaveBgContainer: {
    position: 'relative'
  },
  whiteRoundedBgContainer: {
    position: 'relative'
  },
  whiteRoundedBg: {
    width: '100%',
    height: metrics.screenWidth * 0.187,
    marginTop: -2
  },
  rules: {
    fontSize: 21,
    paddingTop: 15,
    paddingBottom: 50,
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: '#ab327b',
  },
  footerButtons: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ab327b',
    padding: '50px 0px 30px',
  },
  enterButton: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    margin: '0px 5px'
  },
  navButton: {
    justifyContent: 'space',
    alignItems: 'center',
    backgroundColor: '#ab327b',
    color: '#fff',
    margin: '0px 5px'
  },
  enterButtonText: {
    color: '#ab327b',
    fontWeight: '600'
  },
  enterButtonTextWhite: {
    color: '#fff',
    fontWeight: '600'
  },
  form: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginHorizontal: 30,
    marginTop: 20,
    elevation: 0,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderColor: 'rgba(0,0,0,0.05)'
  },
  formItem: {
    paddingVertical: 15,
    paddingLeft: 20,
    paddingRight: 10,
    borderBottomWidth: 1,
    borderColor: 'rgba(0,0,0,0.05)'
  },
  formLastItem: {
    paddingVertical: 15,
    paddingLeft: 20,
    paddingRight: 10
  },
  formInput: {
    fontSize: 15
  },
  button: {
    fontSize: 14,
    fontWeight: 600,
    color: '#ab327b',
    padding: '0 20px',
    lineHeight: '46px',
    borderRadius: 25,
  },
  buttonWhite: {
    fontSize: 14,
    color: '#fff',
    backgroundColor: '#ab327b',
    fontWeight: 600,
    padding: '0 20px',
    lineHeight: '46px',
    borderRadius: 25,
  },
  fullButton: {
    width: metrics.screenWidth * 0.7,
    height: 50,
    borderRadius: 25,
    overflow: 'hidden'
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 10,
    paddingVertical: 3
  },
  tableCol: {
    flex: 1,
    color: '#ffffff',
    fontSize: 15
  },
  options: {
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#ffffff'
  },
  option: {
    display: 'flex',
    flex: 0,
    flexDirection: 'row',
    alignItems: 'flex-start',
    borderRadius: 35,
    backgroundColor: '#efefef',
    elevation: 8,
    marginBottom: 20,
    padding: 10,
    cursor: 'pointer'
  },
  checkBox: {
    flex: 0,
    height: 50,
    width: 50,
    backgroundColor: '#F5F5F5',
    borderRadius: 25,
    marginRight: 15
  },
  checkedImage: {
    height: 50,
    width: 50,
  },
  optionTextHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 50
  },
  optionText: {
    fontSize: 16,
    color: '#000000'
  },
  header: {
    display: 'flex',
    height: 100,
    paddingTop: 30,
    paddingBottom: 10,
    paddingLeft: 30,
    paddingRight: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  timerText: {
    fontSize: 21,
    color: '#ffffff'
  },
  confirmationItem: {
    borderRadius: 30,
    overflow: 'hidden',
    backgroundColor: '#E2E2E2',
    height: 60,
    width: 60,
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px 10px'
  }
}
