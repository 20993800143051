// SPACING

export const spacing = {
  xs: 4,
  sm: 8,
  md: 12,
  lg: 16,
  xl: 20,
  xxl: 32
};

// Z-INDEX
export const zIndex = {
  navbar: 1000,
  popover: 1100,
  tooltip: 1200,
  navbarFixed: 1300,
  modal: 2000
};

// NAVBAR
export const NAVBAR_HEIGHT = '5rem';

// SIDEBAR
export const SIDEBAR_WIDTH = '30rem';
export const SIDEBAR_MINIMIZED_WIDTH = '5rem';

// Screen sizes
export const screenSizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
  currentHeight:  window.innerHeight,
  currentWidth: window.innerWidth
};

const screenWidth = window.outerWidth < 536 ? window.outerWidth : 536;

const screenHeight = window.outerHeight - 102;

const designRatio = 1.5

export default {
  screenSizes, spacing, NAVBAR_HEIGHT, SIDEBAR_WIDTH, SIDEBAR_MINIMIZED_WIDTH, screenWidth, designRatio, screenHeight
}
