import { call, put } from 'redux-saga/effects'
import accountAction from '../store/account-redux'

export function* account (api, action) {
  let response = null;
  try {
    response = yield call(api.user, action.task, action.params);
    if (
      response !== null &&
      response.ok === true &&
      response.data !== null &&
      response.data.data !== null
    ) {
      yield put(accountAction.userSuccess(action.task, response.data.data))
    } else {
      if (response.data) {
        yield put(accountAction.userFailure(response.data))
      } else {
        yield put(accountAction.userFailure(response.originalError))
      }
    }
  } catch (error) {
    yield put(accountAction.userFailure(response.problem))
  }
}
