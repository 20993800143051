import apisauce from 'apisauce';
import config from '../config/app-config';

const create = (baseURL = config.endPointUrl) => {

  const api = apisauce.create({
    baseURL: 'https://quiz.papdi.or.id/',
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    credentials: 'include',
    withCredentials: true,
    timeout: 5000
  });

  api.addAsyncRequestTransform(request => async () => {
    console.log(request)
  });

  api.addResponseTransform(response => {
    console.log(response)
  });

  const user = (task, parameters) => {
    let params = new FormData();
    for (let key in parameters) {
      params.append(key, parameters[key] ? parameters[key] : '')
    }
    switch (task) {
      case 'fblogin':
        return api.post('/user?task=fblogin', params);
      case 'glogin':
        return api.post('/user?task=glogin', params);
      case 'login':
        return api.post('/user', params);
      case 'userGroups':
        return api.get('/user/user-groups');
      case 'register_save':
        return api.post('/user?task=register_save', params);
      case 'save':
        return api.post('/user?task=save', params);
      case 'updatePropict':
        return api.post('/user?task=propict', params);
      case 'getProfile':
        return api.get('/user');
      case 'forgotPassword':
        return api.post('/user?task=requestreset', params);
      case 'confirmReset':
        return api.post('/user?task=confirmReset', params);
      case 'completereset':
        return api.post('/user?task=completereset', params);
      case 'logout':
        return api.get('/user?task=logout&request_type=api', params)
    }
  };

  const quiz = (task, parameters) => {
    let params = new FormData();
    for (let key in parameters) {
      params.append(key, parameters[key] ? parameters[key] : '')
    }
    switch (task) {
      case 'get':
        return api.get(parameters.url.replace('php5/quiz/', ''));
      case 'getQuiz':
        return api.get(parameters.url.replace('php5/quiz/', ''), {passkey: parameters.passkey});
      case 'getCategories':
        return api.get('/quiz', parameters);
      case 'submit':
        return api.post('/user?task=submitQuiz', params)
    }
  };

  return {
    user,
    quiz
  }
};

export default {
  create
}

