import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './Styles'
import Images from '../../styles/Images'
import { readableTime } from '../../utils/Formater'
import API from '../../services/api'
import Container from "@material-ui/core/Container";
import PageLoader from "../../components/page-loader";
import {ButtonBase} from "@material-ui/core";

const api = API.create();

class QuizRankingScreen extends Component {
  constructor (props) {
    super(props);
    const routerState = window.history.state;
    this.state = {
      detail: routerState.state,
      scoreBoard: null,
      loading: true
    }
  }

  async getScoreboard () {
    this.setState({
      loading: true,
      refreshing: false
    });
    const result = await api.quiz('get', {
      url: 'leaderboard?quiz_id=' + this.state.detail.id,
    });
    this.setState({
      scoreBoard: result.data.data ? result.data.data : null,
      loading: false
    })
  }

  componentDidMount () {
    this.getScoreboard()
  }

  renderScoreBoard () {
    let items = [(
      <div key={'title'} style={{...styles.tableRow, paddingLeft: 0, borderBottomWidth: 1, borderColor: 'rgba(255,255,255,0.3)'}}>
        <div style={{...styles.tableCol, fontWeight: '600', flex: '0 0 auto', width: 35, textAlign: 'left', marginRight: 10}}>{'#'}</div>
        <div style={{...styles.tableCol, fontWeight: '600'}}>{'Nama'}</div>
        <div style={{...styles.tableCol, fontWeight: '600', textAlign: 'center', flex: '0 0 auto', width: 60}}>{'Skor'}</div>
        <div style={{...styles.tableCol, fontWeight: '600', textAlign: 'right', flex: '0 0 auto', width: 120}}>{'Waktu'}</div>
      </div>
    )];
    if (this.state.scoreBoard === null || this.state.scoreBoard.leaderboard === null) {
      return null
    }
    this.state.scoreBoard.leaderboard.map((item, index) => {
      let time = readableTime(parseInt(item.duration));
      const odd = Math.abs(index % 2) === 1;
      items.push(
        <div key={item.rank} style={{...styles.tableRow, backgroundColor: odd ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0)'}}>
          <div style={{...styles.tableCol, flex: '0 0 auto', width: 35, marginRight: 10}}>{item.rank}</div>
          <div style={{...styles.tableCol}}>{item.name}</div>
          <div style={{...styles.tableCol, textAlign: 'center', flex: '0 0 auto', width: 60}}>{item.score}</div>
          <div style={{...styles.tableCol, textAlign: 'right', flex: '0 0 auto', width: 120}}>{time.formated}</div>
        </div>
      )
    });
    return items
  }

  renderGroupScoreBoard () {
    let items = [(
      <div key={'title'} style={{...styles.tableRow, paddingLeft: 0, borderBottomWidth: 1, borderColor: 'rgba(255,255,255,0.3)'}}>
        <div style={{...styles.tableCol, fontWeight: '600', flex: '0 0 auto', width: 35, textAlign: 'left', marginRight: 10}}>{'#'}</div>
        <div style={{...styles.tableCol, fontWeight: '600'}}>{'Group'}</div>
        <div style={{...styles.tableCol, fontWeight: '600', textAlign: 'right', flex: '0 0 auto', width: 60}}>{'Skor'}</div>
      </div>
    )];
    if (this.state.scoreBoard === null || this.state.scoreBoard.leaderboard === null) {
      return null
    }
    this.state.scoreBoard.groupLeaderboard.map((item, index) => {
      const odd = Math.abs(index % 2) === 1;
      items.push(
        <div key={item.rank} style={{...styles.tableRow, backgroundColor: odd ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0)'}}>
          <div style={{...styles.tableCol, flex: '0 0 auto', width: 35}}>{item.rank}</div>
          <div style={{...styles.tableCol}}>{item.group_name}</div>
          <div style={{...styles.tableCol, textAlign: 'right', flex: '0 0 auto', width: 100}}>{item.group_score}</div>
        </div>
      )
    });
    return items
  }

  render () {
    const winWidth = window.outerWidth < 536 ? window.outerWidth : 536;
    return (
      <div className={'flexBox'}  style={{
        ...styles.container,
        backgroundColor: '#ab327b',
        padding: 0
      }}>
        <PageLoader loading={this.state.loading}/>

          <div style={styles.details}>
            <div style={{...styles.title, color: 'inherit'}}>{this.state.detail.title}</div>
            <div style={{...styles.description, color: 'inherit'}}>{this.state.detail.description}</div>
          </div>
          <div style={styles.whiteWaveBgContainer}>
            <img src={Images.whiteWaveBg} style={styles.whiteWaveBg} />
          </div>
        <Container maxWidth={'md'}>
          <div style={{paddingTop: 0, paddingLeft: 20, paddingRight: 20, paddingBottom: 30, backgroundColor: '#ab327b'}}>
            <div style={{marginBottom: 40}}>
              <div style={{textAlign: 'center', marginBottom: 15, fontSize: 18, fontWeight: 'bold', color: '#ffffff'}}>Group Skor</div>
              {!this.state.loading ? this.renderGroupScoreBoard() : <div style={{padding: 30, textAlign: 'center', color: '#ffffff'}}>Loading..</div>}
            </div>
            <div>
              <div style={{textAlign: 'center', marginBottom: 15, fontSize: 18, fontWeight: 'bold', color: '#ffffff'}}>Skor Tertinggi Personal</div>
              {!this.state.loading ? this.renderScoreBoard() : <div style={{padding: 30, textAlign: 'center', color: '#ffffff'}}>Loading..</div>}
            </div>
          </div>
        <div style={{...styles.footerButtons, paddingBottom: 30}}>
          <ButtonBase onClick={() => this.props.history.replace('quiz-loading', this.state.detail)} variant="contained" style={{
            color: '#fff',
            padding: 20,
            backgroundColor: '#c05994',
            width: '100%',
            borderRadius: 30,
            fontSize: 14,
            fontWeight: '600',
            marginBottom: 20
          }}>Mulai Quiz</ButtonBase>
        </div>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizRankingScreen)
