import React, {Component} from 'react'
import {connect} from 'react-redux'
import AccountAction from '../../store/account-redux'
import PageLoader from "../../components/page-loader";
import Container from "@material-ui/core/Container";
import InputBase from "@material-ui/core/InputBase";
import Images from "../../styles/Images";
import {screenSizes} from "../../styles/metrics";
import {ButtonBase, Button} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: '',
      passwd: ''
    }
  }

  validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  async login() {
    console.log(this.state);
    let errorMessage = false;

    if ((!this.state.passwd)) {
      errorMessage = 'Password belum terisi!'
    }
    if (!this.validateEmail(this.state.email)) {
      errorMessage = 'Periksa kembali email!'
    }
    if ((!this.state.email)) {
      errorMessage = 'Email belum terisi!'
    }
    if (errorMessage !== false) {
      alert(errorMessage);
      return
    }
    // if everything looks good
    this.props.login({
      email: this.state.email,
      passwd: this.state.passwd,
      task: 'login'
    })
  }

  render() {
    return (
      <div  style={{
        paddingTop: 30,
        backgroundImage: 'url(' + Images.bg1 + ')',
        backgroundSize: '100% 80%',
        backgroundRepeat: 'no-repeat'
      }}>
        <Container maxWidth={'sm'}>
        <PageLoader loading={this.state.loading}/>
        <img src={Images.homeBanner} style={{width: '100%', marginBottom: 20}}/>
          <Paper elevation={3} style={{
          backgroundColor: '#fff',
          paddingVertical: 0,
          height: 160,
          marginBottom: 40,
          borderRadius: 10,
          marginLeft: '8%',
          marginRight: '8%'
        }}>
          <div style={{
            height: '50%',
            paddingLeft: 20,
            paddingTop: 10,
            borderBottomWidth: 1,
            borderBottomColor: '#ccc',
            borderBlockEndStyle: 'solid',
            justifyContent: 'center'
          }}>
            <InputBase onChange={(event) => this.setState({email: event.target.value})} style={{height: '100%', justifyContent: 'center'}}
                       fullWidth id="outlined-full-width" placeholder="Email" variant="filled"
                       value={this.state.email}/>
          </div>
          <div style={{height: '50%', paddingLeft: 20, paddingBottom: 10}}>
            <InputBase onChange={(event) => this.setState({passwd: event.target.value})} style={{height: '100%', justifyContent: 'center'}}
                       fullWidth id="outlined-full-width" placeholder="Password" variant="filled"
                       value={this.state.passwd}/>
          </div>
          </Paper>
        <div style={{marginLeft: '8%', marginRight: '8%'}}>
          <ButtonBase onClick={() => this.login()} variant="contained" style={{
            color: '#fff',
            padding: 20,
            backgroundColor: '#9f2974',
            width: '100%',
            borderRadius: 30,
            fontSize: 14,
            fontWeight: '600',
            marginBottom: 20
          }}>Login</ButtonBase>
          <ButtonBase onClick={() => this.props.history.push('register')} variant="contained" style={{
            color: '#fff',
            padding: 20,
            backgroundColor: '#c05994',
            width: '100%',
            borderRadius: 30,
            fontSize: 14,
            fontWeight: '600',
            marginBottom: 20
          }}>Daftar</ButtonBase>
          <ButtonBase variant="contained" style={{
            color: '#333',
            padding: 20,
            backgroundColor: 'none',
            width: '100%',
            borderRadius: 30,
            fontSize: 14,
            fontWeight: '600'
          }}>Lupa Password?</ButtonBase>
        </div>
        </Container>
        <Container maxWidth={'sm'}>
          <div style={{padding: '0px 40px', marginBottom: 30}}>
          <div style={{textAlign: 'center', marginBottom: 30, marginTop: 50}}>
            Disarankan untuk mendapatkan pengalaman pengguna yang lebih baik
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <a href={'https://apps.apple.com/id/app/papdi-digital-quiz/id1495123447?ls=1'}>
                <img style={{width: '100%', height: 'auto'}} src={Images.appStore} />
              </a>
            </Grid>
            <Grid item xs={6}>
              <a href={'https://play.google.com/store/apps/details?id=com.papdi.quiz'}>
                <img style={{width: '100%', height: 'auto'}} src={Images.playStore} />
              </a>
            </Grid>
          </Grid>
          </div>
        </Container>
      </div>
    )
  }
}

Login.defaultProps = {};

Login.propTypes = {};

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: params => dispatch(AccountAction.userRequest('login', params)),
    logout: params => dispatch(AccountAction.userRequest('logout', params)),
    getProfile: userId => dispatch(AccountAction.userRequest('getProfile', userId))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Login)
