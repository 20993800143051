import metrics from '../../styles/metrics'
export default {
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: 32,
    textAlign: 'center',
    color: '#ffffff',
    fontWeight: '600',
    marginBottom: 30,
    marginTop: 30
  },
  itemWrapper: {
    display: 'flex',
    marginBottom: 10,
    backgroundColor: '#CE7EAA',
    borderRadius: 8,
    overflow: 'hidden',
    elevation: 4
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: metrics.designRatio * 120,
    position: 'relative'
  },
  itemTitle: {
    color: '#ffffff',
    fontSize: 22,
    fontWeight: '600',
    marginBottom: 5
  },
  itemText: {
    color: '#ffffff',
    fontSize: 13
  },
  itemImage: {
    width: '50%',
    height: '100%',
  },
  itemImg: {
    height: '100%',
    display: 'block'
  },
  itemDescription: {
    padding: 20,
    width: 200
  },
  listContainer: {
    paddingHorizontal: 20,
    alignContent: 'center',
    width: metrics.screenWidth
  },
  form: {
    backgroundColor: '#fff',
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    paddingTop: 8
  },
  formItem: {
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: '#ccc'
  },
  formInput: {
    height: 70
  }
}
