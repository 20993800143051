import React, { Component } from 'react'
import { connect } from 'react-redux'
import styles from './Styles'
import Container from "@material-ui/core/Container";
import Images from "../../styles/Images";
import API from '../../services/api';
import PageLoader from "../../components/page-loader";
import AppConfig from "../../config/app-config";
import Grid from "@material-ui/core/Grid";
const api = API.create();

class HomePage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      categories: []
    }
  }

  componentDidMount () {
    this.getCategories()
  }

  getCategories = async (url) => {
    this.setState({
      loading: true,
      fetching: false
    });
    const categories = await api.quiz('getCategories');
    this.setState({
      categories: categories.data.data,
      loading: false
    })
  };

  itemImage (item) {
    if (
      item.category_image !== null &&
      item.category_image !== ''
    ) {
      return AppConfig.imageBaseUrl + item.category_image
    } else {
      return Images.categoryImage
    }
  }

  renderItem (item, index) {
    return (
      <Grid key={index} sm={6} item xs={12}>
        <a
          onClick={() => this.props.history.push('quizlist', item)}
          style={{...styles.itemWrapper, ...styles.item, height: 180, padding: 0}}
        >
          <div style={styles.itemDescription}>
            <div style={styles.itemTitle}>{item.title}</div>
            <div style={styles.itemText}>{item.description}</div>
          </div>
          <div style={styles.itemImage}>
          <img
            style={styles.itemImg}
            src={this.itemImage(item)}
          />
          </div>
        </a>
      </Grid>
    )
  }

  renderList() {
    const list = [];
    this.state.categories.map((item, index) => {
      list.push(
        this.renderItem(item, index)
      )
    });
    if(!list.length){
      return (<div style={{minHeight: 300}} />)
    }
    return list
  }

  render () {
    return (
      <div  style={{
        ...styles.container,
        backgroundImage: 'url(' + Images.bg2 + ')',
        backgroundSize: '100% 50%',
        backgroundRepeat: 'no-repeat'
      }}>
        <Container>
          <PageLoader loading={this.state.loading}/>
          <div style={styles.title}>Pilih Quiz</div>
          <Grid container spacing={3}>
            {this.renderList()}
          </Grid>
        </Container>
        <Container maxWidth={'sm'}>
        <div style={{textAlign: 'center', marginBottom: 30, marginTop: 50}}>
          Disarankan untuk mendapatkan pengalaman pengguna yang lebih baik
        </div>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <a href={'https://apps.apple.com/id/app/papdi-digital-quiz/id1495123447?ls=1'}>
              <img style={{width: '100%', height: 'auto'}} src={Images.appStore} />
            </a>
          </Grid>
          <Grid item xs={6}>
            <a href={'https://play.google.com/store/apps/details?id=com.papdi.quiz'}>
              <img style={{width: '100%', height: 'auto'}} src={Images.playStore} />
            </a>
          </Grid>
        </Grid>
      </Container>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
