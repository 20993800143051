import React, { Component } from 'react'
import moment from 'moment'
import { Pad } from '../utils/Formater'

export default class Timer extends Component {
  static defaultProps = {
    style: null,
    textStyle: null,
    startDateTime: moment(),
    endDateTime: null,
    format: 'h:mm:ss',
    interval: 100,
    method: 'timer',
    timeEndAction: null
  };

  constructor (props) {
    super(props);
    this.state = {
      timer: this.updateTime(),
    }
  }

  componentDidMount () {
    if (this.props.endDateTime !== null){
      this.interval = setInterval(() => {
        this.setState({
          timer: this.updateTime()
        })
      }, this.props.interval)
    }
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  updateTime () {
    const duration = moment.duration(this.props.endDateTime.diff(moment()));
    const timestamp = duration.asMilliseconds();

    if (timestamp < 60) {
      clearInterval(this.interval);
      if (this.props.timeEndAction) {
        this.props.timeEndAction()
      }
    }

    const hour = duration.hours() ? Pad(duration.hours(), 2) + ':' : '';
    const minutes = Pad(duration.minutes(), 2);
    const seconds = Pad(duration.seconds(), 2);
    const miliseconds = parseInt(duration.milliseconds() / 100);
    return hour + minutes + ':' + seconds + '.' + miliseconds
  }

  render () {
    if (this.props.endDateTime === null) {
      return null
    }
    return (
      <div style={this.props.style}>
        <div style={this.props.textStyle}>{this.state.timer}</div>
      </div>
    )
  }
}
