import moment from 'moment'

const MoneyFormat = (amount, decimalCount = 0, decimal = '', thousands = '.', useSymbol = true) => {
  try {
    let mathDecimalCount = Math.abs(decimalCount)
    let dc = isNaN(mathDecimalCount) ? 2 : mathDecimalCount
    const negativeSign = amount < 0 ? '-' : ''
    let i = parseInt(Math.abs(Number(amount) || 0).toFixed(dc)).toString()
    let j = (i.length > 3) ? i.length % 3 : 0
    const formated = negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (dc ? decimal + Math.abs(amount - i).toFixed(dc).slice(2) : '')
    return useSymbol ? 'Rp. ' + formated : formated
  } catch (e) {
    console.log(e)
  }
}

const MoneyFormatSimple = (amount) => {
  const result = MoneyFormat(Math.ceil(amount / 1000), 0, '', '.', false)
  return 'Rp ' + result + 'rb'
}

const Capitalize = (string) => {
  return string[0].toUpperCase() + string.slice(1)
}

const Pad = (n, width) => {
  n = n + ''
  return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n
}

const readableTime = (timestamp) => {
  const d = moment.duration(timestamp)
  return {
    milliseconds: d.milliseconds(),
    seconds: d.seconds(),
    minutes: d.minutes(),
    hours: d.hours(),
    timestamp: timestamp,
    formated: Pad(d.hours(), 2) + ':' + Pad(d.minutes(), 2) + ':' + Pad(d.seconds(), 2) + '.' + Pad(d.milliseconds(), 3)
  }
}

export {
  Pad,
  MoneyFormat,
  MoneyFormatSimple,
  Capitalize,
  readableTime
}
