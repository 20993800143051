import React, {Component, Fragment} from 'react';
import {Switch, Route, Redirect, BrowserRouter} from 'react-router-dom';
import HomePage from '../containers/quiz/index';
import QuizRanking from '../containers/quiz/QuizRanking';
import QuizList from '../containers/quiz/QuizList';
import QuizDetail from '../containers/quiz/QuizDetail';
import QuizScreen from '../containers/quiz/QuizScreen';
import QuizPlay from '../containers/quiz/QuizPlay';
import QuizResult from '../containers/quiz/QuizResult';
import Login from '../containers/account/login';
import Register from '../containers/account/register';
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import images from "../styles/Images";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Drawer from "@material-ui/core/Drawer";
import config from '../config/app-config'
import Typography from "@material-ui/core/Typography";
import AccountAction from "../store/account-redux";
import ListItemText from "@material-ui/core/ListItemText";

class PrimaryLayout extends Component {
  constructor (props) {
    super(props);
    this.state = {
      left: false
    }
  }
  toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({ [side]: open });
  };
  sideList(side){
    const {user} = this.props.account
    console.log(user)
    return (
    <div
      role="presentation"
      onClick={this.toggleDrawer(side, false)}
      onKeyDown={this.toggleDrawer(side, false)}
    >
      <div style={{
        backgroundColor: '#992271',
        padding: 40,
        width: 260,
        textAlign: 'center'
      }}>
        <Paper style={{
          borderRadius: 200,
          height: 180,
          width: 180,
          overflow: 'hidden',
          border: '5px solid #ce7eaa',
          marginBottom: 20
        }}>
          <img style={{width: '100%', height: 'auto'}} src={config.imageBaseUrl + user.propict} />
        </Paper>
        <div style={{color: '#fff', fontSize: 16}}>
          {user.name}
        </div>
        {user.group
          ? <div style={{color: '#fff', fontSize: 16}}>
            Grup {user.group}
          </div>
          : null
        }
      </div>
      <List>
        <ListItem onClick={() => this.props.logout()}>
          <ListItemText primary={'Logout'} />
        </ListItem>
      </List>
    </div>
  )};
  unrestricted = (
    <Switch>
      <Route path="/register" component={Register} />
      <Route path="/login" component={Login} />
      <Redirect to="/login" />
    </Switch>
  );
  restricted = (
    <Fragment>
      <div style={{position: 'absolute', top: 0, left: 0, right: 0}}>
        <Container>
          <div style={{marginTop: 35}}>
            <Button onClick={this.toggleDrawer('left', true)}>
              <img src={images.menuToggle} style={{height: 30, width: 30}} />
            </Button>
          </div>
        </Container>
      </div>
      <Switch>
        <Route path="/quiz-result" component={QuizResult} />
        <Route path="/quiz-play" component={QuizPlay} />
        <Route path="/quiz-loading" component={QuizScreen} />
        <Route path="/quiz-ranking" component={QuizRanking} />
        <Route path="/quiz-detail" component={QuizDetail} />
        <Route path="/quizlist" component={QuizList} />
        <Route path="/" exact component={HomePage} />
        <Redirect to="/" />
      </Switch>
    </Fragment>
  );
  render () {
    return (
      <Fragment>
        <BrowserRouter>
          {this.props.account.guest ? this.unrestricted : this.restricted}
        </BrowserRouter>
        {!this.props.account.guest &&
        <Drawer open={this.state.left} onClose={this.toggleDrawer('left', false)}>
          {this.sideList('left')}
        </Drawer>
        }
      </Fragment>
    )
  }
};

const mapStateToProps = (state) => {
  const { account } = state;
  return {
    account
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: params => dispatch(AccountAction.userRequest('logout', params))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryLayout)
