import React, {Component} from 'react'
import LinearProgress from "@material-ui/core/LinearProgress";


export default class PageLoader extends Component {
  static defaultProps = {
    loading: false
  };

  render () {
    if (this.props.loading){
      return (
        <LinearProgress/>
      )
    } else {
      return null
    }
  }
}