import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from './Styles'
import Images from '../../styles/Images'
import API from '../../services/api'
import Container from "@material-ui/core/Container";
import PageLoader from "../../components/page-loader";
import {ButtonBase} from "@material-ui/core";

const api = API.create();

class QuizScreen extends Component {
  constructor(props) {
    super(props);
    const routerState = window.history.state;
    console.log(routerState.state);
    this.state = {
      passkey: null,
      detail: routerState.state,
      quiz: null,
      requestPassword: false,
      loading: true
    };
    this.passwordPrompt = this.passwordPrompt.bind(this)
  }

  componentDidMount() {
    if (this.state.detail.secured) {
      setTimeout(() => {
        this.passwordPrompt()
      }, 600)
    } else {
      this.getQuiz()
    }
  }

  passwordPrompt(message = null) {
    this.setState({
      requestPassword: true
    });
  }

  async getQuiz() {
    const url = this.state.detail.link;
    console.log(this.state)
    this.setState({
      loading: true
    });
    const quiz = await api.quiz('getQuiz', {
      url: url,
      passkey: this.state.passkey
    });
    console.log(quiz.data.data.error_code);
    if (quiz.data.data.error_code === 401) {
      this.passwordPrompt(quiz.data.data.error_message);
      return
    }
    if (quiz.data.data !== null && quiz.data.data.error_code === 403) {
      alert('Akses Ditolak', quiz.data.data.error_message, [{
          text: 'Kembali',
          onPress: () => this.props.history.goBack()
        }],
        {
          cancelable: false
        })
    }
    if (quiz.data.data !== null && quiz.data.data.error !== false) {
      this.setState({
        quiz: quiz.data.data,
        errorCode: null,
        loading: false
      })
    }
  }

  renderLoading() {
    return (
      <div style={{flex: 1, minHeight: '100vh'}}>
        <div style={styles.details}>
          <img src={Images.intro1} style={styles.loadingImage}/>
          <div style={{...styles.description, color: '#ab327b'}}>Mengunduh soal, mohon tunggu</div>
        </div>
        <div style={styles.whiteWaveBgContainer}>
          <img src={Images.whiteWaveBg} style={styles.whiteWaveBg}/>
        </div>
        <div style={styles.rules}>
          <div style={styles.title}>Loading...</div>
        </div>
      </div>
    )
  }

  renderPasswordRequest() {
    return (
      <div style={{flex: 1, minHeight: '100vh'}}>
        <div style={styles.details}>
          <img src={Images.intro1} style={styles.loadingImage}/>
          <div style={{...styles.description, color: '#ab327b'}}>Mengunduh soal, mohon tunggu</div>
        </div>
        <div style={styles.whiteWaveBgContainer}>
          <img src={Images.whiteWaveBg} style={styles.whiteWaveBg}/>
        </div>
        <div style={styles.rules}>
          <div style={styles.title}>{this.state.detail.title}</div>
          <div style={styles.description}>{this.state.detail.description}</div>
        </div>
        <div style={styles.footerButtons}>
          <div style={{...styles.fullButton, ...styles.enterButton, opacity: 0.5}}>
            <div style={styles.enterButtonText}>Loading...</div>
          </div>
        </div>
      </div>
    )
  }

  renderReady() {
    return (
      <div style={{flex: 1, minHeight: '100vh'}}>
        <div style={{...styles.details}}>
          <img src={Images.intro1} style={styles.loadingImage}/>
          <div style={{...styles.description, color: '#A65381', fontSize: 21, fontWeight: 700}}>Quiz siap</div>
          <div style={{...styles.description, color: '#A65381'}}>Tekan tombol start untuk memulai</div>
        </div>
        <div style={styles.whiteWaveBgContainer}>
          <img src={Images.whiteWaveBg} style={styles.whiteWaveBg}/>
        </div>
        <Container>
        <div style={{...styles.footerButtons, marginTop: 30}}>
          <ButtonBase onClick={() => this.props.history.replace('quiz-play', this.state.quiz)} variant="contained" style={{
            color: '#fff',
            padding: 20,
            backgroundColor: '#c05994',
            width: '100%',
            borderRadius: 30,
            fontSize: 14,
            fontWeight: '600',
            marginBottom: 20
          }}>Mulai Quiz</ButtonBase>
        </div>
        </Container>
      </div>
    )
  }

  render() {
    return (
      <div style={{
        backgroundColor: '#ab327b'
      }}>

        <PageLoader loading={this.state.loading}/>
        {this.state.loading ? this.renderLoading() : (
          this.state.requestPassword ? this.renderPasswordRequest() : this.renderReady()
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizScreen)
