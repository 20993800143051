// leave off @2x/@3x
const images = {
  bg1: require('../images/bg1.png'),
  bg2: require('../images/bg2.png'),
  introBg: require('../images/introBg.png'),
  intro1: require('../images/intro1.png'),
  intro2: require('../images/intro2.png'),
  intro3: require('../images/intro3.png'),
  homeBanner: require('../images/homeBanner.png'),
  formShadow: require('../images/formShadow.png'),
  fbButton: require('../images/fbButton.png'),
  gButton: require('../images/gButton.png'),
  loginBg: require('../images/login-bg.png'),
  gradientBg1: require('../images/gradient-bg1.png'),
  categoryImage: require('../images/categoryImage.png'),
  greenGradient: require('../images/greenGradient.png'),
  pinkGradient: require('../images/pinkGradient.png'),
  whiteRoundedBg: require('../images/whiteRoundedBg.png'),
  whiteWaveBg: require('../images/whiteWaveBg.png'),
  playHeaderBg: require('../images/playHeaderBg.png'),
  pinkRoundedBg: require('../images/pinkRoundedBg.png'),
  checked: require('../images/checked.png'),
  selected: require('../images/selectedbg.png'),
  menuToggle: require('../images/menuToggle.png'),
  appStore: require('../images/appStore.png'),
  playStore: require('../images/playStore.png'),
};

export default images
