import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from './Styles'
import Images from "../../styles/Images";
import PageLoader from "../../components/page-loader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {ButtonBase} from "@material-ui/core";

class QuizDetailScreen extends Component {
  constructor(props) {
    super(props);
    const routerState = window.history.state;
    this.state = {
      detail: routerState.state,
      loading: false
    }
  }

  startQuiz() {
    this.props.history.push('quiz-loading', this.state.detail)
  }

  render() {
    return (
      <div style={{
        ...styles.container,
        backgroundColor: '#ab327b',
        padding: 0
      }}>

        <PageLoader loading={this.state.loading}/>
        <div style={styles.details}>
          <div style={{...styles.title, color: 'inherit'}}>{this.state.detail.title}</div>
          <div style={{...styles.description, color: 'inherit'}}>{this.state.detail.description}</div>
        </div>
        <div style={styles.whiteRoundedBgContainer}>
          <img src={Images.whiteRoundedBg} style={styles.whiteRoundedBg}/>
        </div>
        <Container maxWidth={'md'}>
          <div style={styles.rules}>
            <div dangerouslySetInnerHTML={{__html: this.state.detail.rules}}/>
          </div>
          <div style={styles.footerButtons}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ButtonBase onClick={() => this.props.history.push('quiz-ranking', this.state.detail)} variant="contained" style={{
                  color: '#fff',
                  padding: 20,
                  backgroundColor: '#c05994',
                  width: '100%',
                  borderRadius: 30,
                  fontSize: 14,
                  fontWeight: '600',
                  marginBottom: 20
                }}>Lihat Skor</ButtonBase>
              </Grid>
              <Grid item xs={6}>
                <ButtonBase onClick={() => this.startQuiz()} variant="contained" style={{
                  color: '#fff',
                  padding: 20,
                  backgroundColor: '#c05994',
                  width: '100%',
                  borderRadius: 30,
                  fontSize: 14,
                  fontWeight: '600',
                  marginBottom: 20
                }}>Mulai Quiz</ButtonBase>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizDetailScreen)
