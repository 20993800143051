import React, { Component } from 'react'
import { connect } from 'react-redux'
import AccountAction from '../../store/account-redux'
import Images from "../../styles/Images";
import styles from './Styles'
import {screenSizes} from "../../styles/metrics";
import Container from "@material-ui/core/Container";
import API from '../../services/api';
import PageLoader from "../../components/page-loader";
import { validateEmail } from '../../utils/Validator'
import {Button, ButtonBase} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const api = API.create();

class Register extends Component {
  constructor (props) {
    super(props);
    this.state = {
      name: '',
      email: '', // null,
      password: '', // null,
      password2: '',
      areaCode: '+62',
      phone: '',
      gender: '',
      location: '',
      group: '',
      firebasePermissionRequest: true,
      groups: []
    }
  }

  componentDidMount () {
    this.getUserGroups()
  }

  componentDidUpdate (prevProps, prevState, snapShot) {
  }
  

  async getUserGroups () {
    const result = await api.user('userGroups');
    if ((!!result.data) && (!!result.data.data)) {
      const { data } = result.data;
      this.setState({
        groups: data
      })
    }
  }

  renderGroupOptions () {
    let groups = [];
    this.state.groups.map((item, index) => {
      groups.push(
        <option key={item.id} label={item.title} value={item.id} />
      );
      return item
    });
    return groups
  }

  async SignUp () {
    let errorMessage = false;
    if (!this.state.location) {
      errorMessage = 'Kota tinggal belum terisi!'
    }
    if (!this.state.gender) {
      errorMessage = 'Gender belum terpilih!'
    }
    if (!this.state.phone) {
      errorMessage = 'Nomor telepon belum terisi'
    }
    if (this.state.password !== this.state.password2) {
      errorMessage = 'Password tidak cocok!'
    }
    if (!this.state.password2) {
      errorMessage = 'Ulangi password belum terisi!'
    }
    if (this.state.password.length < 6) {
      errorMessage = 'Password minimal 6 karakter!'
    }
    if (!this.state.password) {
      errorMessage = 'Password belum terisi!'
    }
    if (!validateEmail(this.state.email)) {
      errorMessage = 'Periksa kembali email anda!'
    }
    if (!this.state.email) {
      errorMessage = 'Email belum terisi'
    }
    if (!this.state.name) {
      errorMessage = 'Nama belum terisi!'
    }

    if (errorMessage !== false) {
      alert(errorMessage);
      return
    }

    this.props.signup({
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
      areaCode: '+62',
      phone: this.state.areaCode + this.state.phone,
      gender: this.state.gender,
      location: this.state.location,
      group: this.state.group,
      task: 'register_save'
    })
  }

  render () {
    const winWidth = window.outerWidth < 536 ? window.outerWidth : 536
    const self = this;
    return (
      <div  style={{
        paddingTop: 30,
        backgroundImage: 'url(' + Images.bg1 + ')',
        backgroundSize: '100% 80%',
        height: screenSizes.currentHeight,
        backgroundRepeat: 'no-repeat'
      }}>
        <Container maxWidth={'sm'}>
        <PageLoader loading={this.props.account.fetching} />
        <img src={Images.homeBanner} style={{width: '100%', marginBottom: 20}}/>
        <div style={{margin: '0px 8%', padding: '0px 0px'}}>
          <div className={'flexBox'} style={styles.form}>
            <div style={styles.formItem}>
              <input type="text"
                onChange={value => {
                  console.log(value.currentTarget)
                  this.setState({ name: value })
                }}
                placeholder='Nama lengkap*'
                value={this.state.name}
                style={styles.formInput}
              />
            </div>
            <div style={styles.formItem}>
              <input type="text"
                onChange={value => this.setState({ email: value })}
                placeholder='Email*'
                value={this.state.email}
                style={styles.formInput}
              />
            </div>
            <div style={styles.formItem}>
              <input type="text"
                autoCapitalize='none'
                onChange={value => {
                  return this.setState({ password: value })
                }}
                placeholder='Buat Password*'
                value={this.state.password}
                style={styles.formInput}
              />
            </div>
            <div style={styles.formItem}>
              <input type="text"
                autoCapitalize='none'
                onChange={value => {
                  return this.setState({ password2: value })
                }}
                placeholder='Ulangi password*'
                value={this.state.password2}
                style={styles.formInput}
              />
            </div>
            <div style={{ flexDirection: 'row', alignItems: 'flex-start', alignContent: 'flex-start' }}>
              <div style={{...styles.formItem, width: 100, flexGrow: 1, paddingRight: 20 }}>
                <div style={{ flexDirection: 'row', alignItems: 'flex-start', alignContent: 'flex-start' }}>
                  <input type="text"  
                    autoCapitalize='none'
                    maxLength={3}
                    onChange={value => this.setState({ areaCode: value })}
                    value={'+62'}
                    style={{...styles.formInput, width: 50, paddingRight: 0 }}
                  />
                  <input type="text"
                    autoCapitalize='none'
                    onChange={value => this.setState({ phone: value })}
                    placeholder='Nomor telepon*'
                    value={this.state.phone}
                    style={{...styles.formInput, flexGrow: 1 }}
                  />
                </div>
              </div>
              <div style={{...styles.formItem, flexShrink: 1, marginTop: -1 }}>
                <div style={styles.formInput}>
                  <select
                    onChange={(itemValue) => this.setState({ gender: itemValue })}
                    style={{ ...styles.formInput, width: 130 }}
                  >
                    <option color={'#9f9f9f'} label='Gender*' value=''>Gender</option>
                    <option label='Pria' value={'Pria'}>Pria</option>
                    <option label='Wanita' value={'Wanita'}>Wanita</option>
                  </select>
                </div>
              </div>
            </div>
            <div style={styles.formLastItem}>
              <input
                ref={input => (self._LocationInput = input)}
                onChange={value => this.setState({ location: value })}
                placeholder='Kota tinggal*'
                value={this.state.location}
                style={styles.formInput}
              />
            </div>
            {/* <div style={styles.formLastItem}>
                  <Picker
                    mode='dropdown'
                    iosHeader='Group'
                    iosIcon={false}
                    itemStyle={{
                      fontSize: 12,
                      fontWeight: 'normal',
                      fontStyle: 'normal',
                      letterSpacing: -0.23,
                      color: '#666666'
                    }}
                    placeHolder='Group'
                    selectedValue={this.state.group}
                    onValueChange={(itemValue) => this.setState({ group: itemValue })}
                  >
                    <Picker.Item color={'#9f9f9f'} label='Pilih grup' value='' />
                    {this.renderGroupOptions()}
                  </Picker>
                </div> */}
          </div>
          <div style={{ height: 50, position: 'relative'}}>
            <img
              src={Images.formShadow}
              style={{
                position: 'absolute',
                zIndex: 1,
                width: '100%',
                height: (winWidth - 60) * 0.092
              }} />
          </div>
          <div style={{ justifyContent: 'center', alignItems: 'center', marginBottom: 30 }}>
            <ButtonBase onClick={() => this.SignUp()} variant="contained" style={{
              color: '#fff',
              padding: 20,
              backgroundColor: '#9f2974',
              width: '100%',
              borderRadius: 30,
              fontSize: 14,
              fontWeight: '600',
              marginBottom: 30
            }}>Daftar</ButtonBase>
            <ButtonBase onClick={() => this.props.history.replace('/')}
              variant="contained" style={{
              color: '#333',
              padding: 20,
              backgroundColor: 'none',
              width: '100%',
              borderRadius: 30,
              fontSize: 14,
              fontWeight: '600'
            }}>Kembali ke Login</ButtonBase>
          </div>
        </div>
        </Container>
        <Container maxWidth={'sm'}>
          <div style={{padding: '0px 8%', marginBottom: 30}}>
            <div style={{textAlign: 'center', marginBottom: 30, marginTop: 50}}>
              Disarankan untuk mendapatkan pengalaman pengguna yang lebih baik
            </div>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <a href={'https://apps.apple.com/id/app/papdi-digital-quiz/id1495123447?ls=1'}>
                  <img style={{width: '100%', height: 'auto'}} src={Images.appStore} />
                </a>
              </Grid>
              <Grid item xs={6}>
                <a href={'https://play.google.com/store/apps/details?id=com.papdi.quiz'}>
                  <img style={{width: '100%', height: 'auto'}} src={Images.playStore} />
                </a>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { account } = state;
  return { account }
};

const mapDispatchToProps = (dispatch) => {
  return {
    signup: params => dispatch(AccountAction.userRequest('register_save', params)),
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Register)
