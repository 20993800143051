import React from 'react'
import { connect } from 'react-redux'
import getValue from 'get-value'
import styles from './Styles'
import config from '../../config/app-config'
import API from '../../services/api'
import Images from "../../styles/Images";
import PageLoader from "../../components/page-loader";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import {ButtonBase} from "@material-ui/core";

const api = API.create();

class QuizList extends React.PureComponent {
  constructor (props) {
    super(props);
    const routerState = window.history.state;
    this.state = {
      routerState: routerState.state,
      list: [],
      loading: false,
      refreshing: false
    }
  }

  componentDidMount () {
    this.getList()
  }

  // Render a header?
  renderHeader = () =>
    <div style={{...styles.label, ...styles.sectionHeader}}> - Header - </div>;

  // Render a footer?
  renderFooter = () =>
    <div style={{...styles.label, ...styles.sectionHeader}}> - Footer - </div>;

  // Show this when data is empty
  renderEmpty = () =>
    <div style={{...styles.container, justifyContent: 'center', alignItems: 'center'}}>Loading...</div>;

  keyExtractor = (item, index) => 'cat' + item.id;

  // How many items should be kept im memory as we scroll?
  oneScreensWorth = 20;

  async getList () {
    const url = this.state.routerState.link;
    this.setState({
      loading: true,
      fetching: false
    });
    const request = await api.quiz('get', {
      url: url
    });
    this.setState({
      list: getValue(request, 'data.data', {default: []}),
      loading: false
    })
  }

  renderItem (item, index) {
    return (
      <div key={index} style={styles.listItem}>
        <Grid container spacing={3} style={styles.item}>
          <Grid item xs={6} style={styles.itemDescription}>
            <div style={styles.listItemTitle}>{item.title}</div>
            <div style={styles.listItemText}>{item.description}</div>
          </Grid>
          <Grid item xs={6} style={{textAlign: 'right'}}>
            <ButtonBase onClick={() => this.props.history.push('quiz-detail', item)} variant="contained" style={{
              color: '#fff',
              padding: '10px',
              backgroundColor: '#40a44e',
              borderRadius: 30,
              fontSize: 14,
              fontWeight: '600',
              width: 130
            }}>Pilih</ButtonBase>
          </Grid>
        </Grid>
        <div style={styles.itemLine} />
      </div>
    )
  }

  renderList = () => {
    const list = []
    this.state.list.map((item, index) => {
      list.push(
        this.renderItem(item, index)
      )
    });
    return list
  }


  render () {
    return (
      <div  style={{
        ...styles.container,
        backgroundImage: 'url(' + Images.bg2 + ')',
        backgroundSize: '100% 300px',
        backgroundRepeat: 'no-repeat'
      }}>
        <Container maxWidth={'sm'}>
        <PageLoader loading={this.state.loading}/>
        <div style={styles.title}>Pilih Quiz</div>
        <div style={styles.banner}>
          <div style={styles.itemDescription}>
            <div style={styles.bannerTitle}>{this.state.routerState.title}</div>
            <div style={styles.bannerText}>{this.state.routerState.description}</div>
          </div>
          <div style={styles.itemImage}>
          <img
            src={config.imageBaseUrl + getValue(this.state.routerState, 'category_image')}
            style={styles.itemImg}
          />
          </div>
        </div>
        {this.state.loading
          ? this.renderEmpty()
          : this.renderList()
        }
        </Container>
      </div>
  )
  }
}

const mapStateToProps = (state) => {
  return {
    // ...redux state to props here
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizList)
