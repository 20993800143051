import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
  }

  html {
    /** 1rem = 10px; 10px/16px = 62.5% **/
  }


  ::selection {
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.snow};
  }
`;
