import { takeLatest, all } from 'redux-saga/effects';
import API from 'services/api';
/* ------------- Types ------------- */

import { AccountTypes } from '../store/account-redux';

/* ------------- Sagas ------------- */

import { account } from './account-sagas';

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
const api = API.create();

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // some sagas only receive an action
    takeLatest(AccountTypes.USER_REQUEST, account, api)
  ]);
}
