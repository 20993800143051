import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  userRequest: ['task', 'params'],
  userSuccess: ['method', 'data'],
  userFailure: ['error']
});

export const AccountTypes = Types;
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  guest: true,
  user: null,
  tokens: null,
  fetching: false,
  error: null
});

/* ------------- Selectors ------------- */

export const UserSelectors = {
  getUser: state => state.user,
  isGuest: state => state.guest,
  getBadges: state => state
};

/* ------------- Reducers ------------- */

// request the data from an api
export const userRequest = (state, action) => {
  const { task, params } = action;
  switch (task) {
    case 'login':
      return state.merge({ password: params.password, fetching: true, error: null });
    default:
      return state.merge({ fetching: true, error: null })
  }

};

// successful api request from saga
export const userSuccess = (state, action) => {
  const { method, data } = action;
  switch (method) {
    case 'login':
      if ((!data.id) || data.id === 0) {
        alert('Login gagal.');
        return state
      }
      return state.merge({ user: data, guest: false, fetching: false, error: null }, { deep: true });
    case 'register_save':
      if ((!!data.id) && data.id !== 0) {
        return state.merge({ user: data, guest: false, fetching: false, error: null }, { deep: true })
      }
      return state;
    case 'getProfile':
      if ((!!data.id) && data.id !== 0) {
        return state.merge({ user: data, guest: false, fetching: false, error: null }, { deep: true })
      }
      return state;
    case 'save':
      if ((!!data.id) && data.id !== 0) {
        return state.merge({ user: data, guest: false, fetching: false, error: null }, { deep: true })
      }
      return state;
    case 'updatePropict':
      if ((!!data.id) && data.id !== 0) {
        return state.merge({ user: data, guest: false, fetching: false, error: null }, { deep: true })
      }
      return state;
    case 'refreshToken':
      return state.merge({ tokens: data });
    case 'logout':
      return INITIAL_STATE;
    default:
      return state
  }
};

// Something went wrong somewhere.
export const userFailure = (state, payload) => {
  let errorMessage;
  if (payload.error.message) {
    errorMessage = payload.error.message
  }
  if (payload.error.display_message) {
    errorMessage = payload.error.display_message
  }
  alert('Data Error: ' + errorMessage || 'Opps!! Something went wrong');
  return state.merge({ fetching: false, error: true })
};

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [AccountTypes.USER_REQUEST]: userRequest,
  [AccountTypes.USER_SUCCESS]: userSuccess,
  [AccountTypes.USER_FAILURE]: userFailure
});