import React, {Component} from 'react'
import {connect} from 'react-redux'
import styles from './Styles'
import {readableTime} from '../../utils/Formater'
import Images from "../../styles/Images";
import API from '../../services/api'
import Container from "@material-ui/core/Container";
import {ButtonBase} from "@material-ui/core";

const api = API.create();

class QuizResultScreen extends Component {
  constructor(props) {
    super(props);
    const routerState = window.history.state;
    this.state = {
      result: routerState.state,
      scoreBoard: null,
      loading: true
    }
  }

  componentDidMount() {
    this.submit(this.state.result)
  }

  async submit(params) {
    this.setState({
      loading: true
    });
    const result = await api.quiz('submit', {
      user_id: this.props.user.id,
      quiz_id: params.id,
      correct: params.correct || 0,
      incorrect: params.incorect || 0,
      unanswer: params.unanswer || 0,
      duration: params.totalTime.timestamp,
      end_time: params.endTime,
      start_time: params.startTime,
      score: params.score || 0
    });
    this.setState({
      scoreBoard: result.data ? result.data : null,
      loading: false
    })
  }

  renderLoading() {
    return (
      <div>
        <img src={Images.intro1} style={styles.loadingImage}/>
        <div style={{...styles.description, color: '#A65381'}}>Quiz over</div>
      </div>
    )
  }

  renderScoreBoard() {
    let items = [(
      <div key={'title'} style={{...styles.tableRow, paddingLeft: 0, borderBottomWidth: 1, borderColor: 'rgba(255,255,255,0.3)'}}>
        <div style={{...styles.tableCol, fontWeight: '600', flex: '0 0 auto', width: 35, textAlign: 'left', marginRight: 10}}>{'#'}</div>
        <div style={{...styles.tableCol, fontWeight: '600'}}>{'Nama'}</div>
        <div style={{...styles.tableCol, fontWeight: '600', textAlign: 'center', flex: '0 0 auto', width: 60}}>{'Skor'}</div>
        <div style={{...styles.tableCol, fontWeight: '600', textAlign: 'right', flex: '0 0 auto', width: 120}}>{'Waktu'}</div>
      </div>
    )];

    if (this.state.scoreBoard === null || this.state.scoreBoard.leaderboard === null) {
      return null
    }

    this.state.scoreBoard.leaderboard.map((item, index) => {
      let time = readableTime(parseInt(item.duration));
      const odd = Math.abs(index % 2) === 1;
      items.push(
        <div key={item.rank} style={{...styles.tableRow, backgroundColor: odd ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0)'}}>
          <div style={{...styles.tableCol, flex: '0 0 auto', width: 35, marginRight: 10}}>{item.rank}</div>
          <div style={{...styles.tableCol}}>{item.name}</div>
          <div style={{...styles.tableCol, textAlign: 'center', flex: '0 0 auto', width: 60}}>{item.score}</div>
          <div style={{...styles.tableCol, textAlign: 'right', flex: '0 0 auto', width: 120}}>{time.formated}</div>
        </div>
      )
    });
    return items
  }

  renderGroupScoreBoard() {
    let items = [(
      <div key={'title'} style={{...styles.tableRow, paddingLeft: 0, borderBottomWidth: 1, borderColor: 'rgba(255,255,255,0.3)'}}>
        <div style={{...styles.tableCol, fontWeight: '600', flex: '0 0 auto', width: 35, textAlign: 'left', marginRight: 10}}>{'#'}</div>
        <div style={{...styles.tableCol, fontWeight: '600'}}>{'Group'}</div>
        <div style={{...styles.tableCol, fontWeight: '600', textAlign: 'right', flex: '0 0 auto', width: 60}}>{'Skor'}</div>
      </div>
    )];
    if (this.state.scoreBoard === null || this.state.scoreBoard.leaderboard === null) {
      return null
    }
    this.state.scoreBoard.groupLeaderboard.map((item, index) => {
      const odd = Math.abs(index % 2) === 1;
      items.push(
        <div key={item.rank} style={{...styles.tableRow, backgroundColor: odd ? 'rgba(255,255,255,0.1)' : 'rgba(255,255,255,0)'}}>
          <div style={{...styles.tableCol, flex: '0 0 auto', width: 35}}>{item.rank}</div>
          <div style={{...styles.tableCol}}>{item.group_name}</div>
          <div style={{...styles.tableCol, textAlign: 'right', flex: '0 0 auto', width: 100}}>{item.group_score}</div>
        </div>

      )
    });
    return items
  }

  renderResult() {
    return (
      <div style={{
        paddingHorizontal: 20,
        background: 'url(' + Images.intro3 + ') no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        maxWidth: 600,
        margin: 'auto'
      }}>
        <div style={{...styles.title, color: '#A65381'}}>Result</div>
        <div>
          <div style={{
            ...styles.description,
            fontSize: 60,
            fontWeight: '600',
            lineHeight: 60
          }}>{this.state.scoreBoard.position}</div>
          <div style={{...styles.description}}>Scoreboard Position</div>
        </div>
        <div style={{marginBottom: 20}}>
          <div style={{...styles.description, fontWeight: '600', fontSize: 16, padding: 0}}>Waktu Anda</div>
          <div style={{...styles.description, fontWeight: '600', fontSize: 42}}>{this.state.result.totalTime.formated}</div>
        </div>
        <div style={{...styles.tableRow}}>
          <div style={{...styles.tableCol, width: '25%'}}>
            <div style={{...styles.description, fontSize: 60, fontWeight: 800}}>{this.state.result.correct}</div>
            <div style={{...styles.description, fontSize: 16}}>Benar</div>
          </div>
          <div style={{...styles.tableCol, width: '25%'}}>
            <div style={{...styles.description, fontSize: 60, fontWeight: 800}}>{this.state.result.incorect}</div>
            <div style={{...styles.description, fontSize: 16}}>Salah</div>
          </div>
          <div style={{...styles.tableCol, width: '25%'}}>
            <div style={{...styles.description, fontSize: 60, fontWeight: 800}}>{this.state.result.unanswer}</div>
            <div style={{...styles.description, fontSize: 16}}>Kosong</div>
          </div>
          <div style={{...styles.tableCol, width: '25%'}}>
            <div style={{...styles.description, fontSize: 60, fontWeight: 800}}>{
              isNaN(this.state.result.score) ? 0 : this.state.result.score
            }</div>
            <div style={{...styles.description, fontSize: 16}}>Nilai</div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div style={{
        ...styles.container,
        backgroundColor: '#ab327b',
        padding: 0
      }}>
        <div style={styles.details}>
          {this.state.loading ? this.renderLoading() : this.renderResult()}
        </div>
        <div style={styles.whiteWaveBgContainer}>
          <img src={Images.whiteWaveBg} style={styles.whiteWaveBg}/>
        </div>
        <Container maxWidth={'md'} className={'flexBox'} style={{padding: '20px 20px'}}>
          <div style={{marginBottom: 40}}>
            <div
              style={{textAlign: 'center', marginBottom: 15, fontSize: 18, fontWeight: 'bold', color: '#ffffff'}}>Group
              Skor
            </div>
            {!this.state.loading ? this.renderGroupScoreBoard() :
              <div style={{padding: 30, textAlign: 'center', color: '#ffffff'}}>Loading..</div>}
          </div>
          <div style={{marginBottom: 40}}>
            <div
              style={{textAlign: 'center', marginBottom: 15, fontSize: 18, fontWeight: 'bold', color: '#ffffff'}}>Skor
              Tertinggi Personal
            </div>
            {!this.state.loading ? this.renderScoreBoard() :
              <div style={{padding: 30, textAlign: 'center', color: '#ffffff'}}>Loading..</div>}
          </div>
        </Container>
        <div style={styles.footerButtons}>
          <Container maxWidth={'md'}>
          <ButtonBase onClick={() => this.props.history.replace('/')} variant="contained" style={{
            color: '#fff',
            padding: 20,
            backgroundColor: '#c05994',
            width: '100%',
            borderRadius: 30,
            fontSize: 14,
            fontWeight: '600',
            marginBottom: 20
          }}>Selesai</ButtonBase>
          </Container>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const {account} = state;
  return {
    user: account.user
  }
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizResultScreen)
