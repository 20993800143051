import React, {Component} from 'react'
import {connect} from 'react-redux'
import {readableTime} from '../../utils/Formater'
import metrics from '../../styles/metrics'
import styles from './Styles'
import Images from '../../styles/Images'
import Timer from '../../components/Timer'
import moment from 'moment'
import Container from "@material-ui/core/Container";
import PageLoader from "../../components/page-loader";
import Grid from "@material-ui/core/Grid";
import {ButtonBase} from "@material-ui/core";
import {Prompt} from 'react-router'

class QuizPlayScreen extends Component {
  constructor(props) {
    super(props);
    const routerState = window.history.state;
    console.log(routerState.state);
    this.state = {
      ...routerState.state,
      data: routerState.state.question,
      currentPage: 0,
      showIndex: false,
      safeExit: false
    };
    this.dataLength = 0
  }

  componentDidMount() {
    this.setState({
      startTime: Date.now(),
      mounted: true
    })
  }

  componentDidUpdate() {
    if (this.state.safeExit) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  }

  calculateScore(params = {}) {
    let correctPoin = 0;
    const truePoin = this.state.quiz_true_poin;
    const falsePoin = this.state.quiz_false_poin;
    const emptyPoin = this.state.quiz_unanswered_poin;
    const timePoin = this.state.time_exess_poin;

    const maxScore = this.state.quiz_max_score || 0;
    const maxTime = this.state.ruleTime;
    console.log(this.state);
    console.log(params);
    if (!truePoin) {
      correctPoin = (maxScore / params.total) * params.correct
    } else {
      correctPoin = truePoin * params.correct
    }

    let timeExessPoin = 0;
    if (maxTime > 0 && timePoin > 0) {
      timeExessPoin = Math.floor(moment.duration(maxTime - params.duration).asMinutes()) * timePoin
    }

    let incorrectPoin = falsePoin * params.incorrect;
    let unansweredPoin = emptyPoin * params.unanswered;

    console.log(correctPoin,timeExessPoin, incorrectPoin, unansweredPoin)

    let score = Math.ceil(((correctPoin + timeExessPoin) - incorrectPoin) - unansweredPoin);

    return score
  }

  validateAnswer(timeout) {
    this.setState({safeExit: true}, () => {
      const endTime = Date.now();
      const count = this.state.data.length;
      let correct = 0;
      let incorect = 0;
      let unanswer = 0;
      this.state.data.map((item) => {
        if (!(!item.question) && !(!item.options.length)) {
          if (item.answer !== null) {
            if (item.answer === item.correct_answer) {
              correct++
            } else {
              incorect++
            }
          } else {
            unanswer++
          }
        }
      });
      const params = {
        id: this.state.id,
        startTime: this.state.startTime,
        endTime: endTime,
        totalTime: readableTime(endTime - this.state.startTime),
        correct: correct,
        incorect: incorect,
        unanswer: unanswer,
        score: this.calculateScore({
          correct: correct,
          incorrect: incorect,
          unanswered: unanswer,
          duration: endTime - this.state.startTime,
          total: count
        })
      };
      console.log(params)
      this.props.history.replace('quiz-result', params)
    })
  }

  selectAnswer(id, key) {
    let newData = this.state.data.map((item) => {
      let newItem = {...item};
      if (item.id === id) {
        if (item.answer === key) {
          newItem.answer = null
        } else {
          newItem.answer = key
        }
      }
      return newItem
    });
    this.setState({
      data: newData
    })
  }

  renderOptions(data) {
    let options = [];
    data.options.map((item, index) => {
      options.push(
        <Grid item sm={6} xs={12}>
          <a key={index} style={styles.option} onClick={() => this.selectAnswer(data.id, item.key)}>
            {data.answer !== null && data.answer === item.key
              ? <div style={styles.checkBox}><img src={Images.checked} style={styles.checkedImage}/></div>
              : <div style={styles.checkBox}><img src={Images.checked} style={{...styles.checkedImage, opacity: 0}}/>
              </div>
            }
            <div style={styles.optionTextHolder}>
              <div style={styles.optionText}>{item.value}</div>
            </div>
          </a>
        </Grid>
      )
    });
    return <Grid container spacing={3}>{options}</Grid>
  }

  renderItems() {
    if (this.state.showIndex) {
      return null
    }
    const index = this.state.currentPage;
    const item = this.state.data[index];
    return (
      <div style={{flex: 1}} key={'id' + item.id}>
        <div style={{background: '#ab327b'}}>
          <Container maxWidth={'md'}>
            <div style={{...styles.details, padding: 30, backgroundColor: 'transparent', color: '#fff', fontSize: 22}}>
              <div className={'questionItem'} dangerouslySetInnerHTML={{__html: item.question}}/>
            </div>
          </Container>
        </div>
        <div style={{...styles.whiteRoundedBgContainer, backgroundColor: '#ffffff'}}>
          <img src={Images.pinkRoundedBg} style={styles.whiteRoundedBg}/>
        </div>
        <div style={{backgroundColor: '#ffffff'}}>
          <div style={styles.options}>
            <Container maxWidth={'md'}>
              {this.renderOptions(item)}
            </Container>
          </div>
          <Container maxWidth={'md'}>
            <div style={{...styles.footerButtons, backgroundColor: 'transparent'}}>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <ButtonBase
                    onClick={() => this.goPrev()}
                    variant="contained"
                    disabled={index === 0}
                    style={{
                      color: '#fff',
                      padding: 20,
                      backgroundColor: '#c05994',
                      width: '100%',
                      borderRadius: 30,
                      fontSize: 14,
                      fontWeight: '600',
                      marginBottom: 20
                    }}>Sebelum</ButtonBase>
                </Grid>

                <Grid item xs={4}>
                  <ButtonBase
                    onClick={() => this.toLast()}
                    variant="contained"
                    style={{
                      color: '#fff',
                      padding: 20,
                      backgroundColor: '#c05994',
                      width: '100%',
                      borderRadius: 30,
                      fontSize: 14,
                      fontWeight: '600',
                      marginBottom: 20
                    }}>Index</ButtonBase>
                </Grid>
                <Grid item xs={4}>
                  <ButtonBase
                    onClick={() => this.goNext()}
                    variant="contained"
                    style={{
                      color: '#fff',
                      padding: 20,
                      backgroundColor: '#c05994',
                      width: '100%',
                      borderRadius: 30,
                      fontSize: 14,
                      fontWeight: '600',
                      marginBottom: 20
                    }}>Lanjut</ButtonBase>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
      </div>
    )
  }

  renderConfirmationItem(index, answer) {
    if (!answer) {
      return (
        <div style={styles.confirmationItem}>
          <div style={{
            textAlign: 'center',
            letterSpacing: '-4px',
            width: 55,
            fontSize: 36,
            fontWeight: '600',
            color: '#ffffff'
          }}>{index + 1}</div>
        </div>
      )
    } else {
      return (
        <div style={styles.confirmationItem}>
          <img
            src={Images.selected}
            style={{position: 'absolute', width: 60, height: 60}}
          />
          <div style={{
            textAlign: 'center',
            letterSpacing: '-4px',
            width: 55,
            fontSize: 36,
            fontWeight: '600',
            color: '#ffffff',
            zIndex: 999,
            position: 'relative'
          }}>{index + 1}</div>
        </div>
      )
    }
  }

  renderConfirmation() {
    if (!this.state.showIndex) {
      return null
    }
    let items = [];
    let unanswer = 0;
    let clonedData = [...this.state.data];
    clonedData.map((item, index) => {
      item.answer === null && unanswer++;
      items.push(
        <div
          key={index}
          style={{
            marginBottom: 20,
            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'center',
          }}
        >
          <a
            onClick={() => this.toIndex(index)}
          >
            {this.renderConfirmationItem(index, item.answer)}
          </a>
        </div>
      )
    });
    return (
      <div style={{flex: 1}} key={'last'}>
        <div style={{background: '#ab327b'}}>
          <div style={{...styles.details, background: 'none'}}>
            <div style={{
              background: 'none',
              textAlign: 'center',
              paddingTop: 20,
              color: '#ffffff',
              fontWeight: '500',
              fontSize: 16
            }}>
              {unanswer > 0 ? 'Terdapat ' + unanswer + ' soal belum terjawab' : 'Semua soal sudah terjawab'}
            </div>
          </div>
        </div>
        <div style={{...styles.whiteRoundedBgContainer, backgroundColor: '#ffffff'}}>
          <img src={Images.pinkRoundedBg} style={styles.whiteRoundedBg}/>
        </div>
        <div style={{flex: 1, backgroundColor: '#ffffff', minHeight: metrics.screenHeight - 230}}>
          <Container>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignContent: 'flex-start',
              padding: 30,
              paddingTop: 0,
              justifyContent: 'center',
              marginBottom: 30
            }}>
              {items}
            </div>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <ButtonBase
                  onClick={() => this.setState({showIndex: false})}
                  variant="contained"
                  style={{
                    color: '#fff',
                    padding: 20,
                    backgroundColor: '#c05994',
                    width: '100%',
                    borderRadius: 30,
                    fontSize: 14,
                    fontWeight: '600',
                    marginBottom: 20
                  }}>Sebelum</ButtonBase>
              </Grid>
              <Grid item xs={6}>
                <ButtonBase
                  onClick={() => this.validateAnswer()}
                  variant="contained"
                  style={{
                    color: '#fff',
                    padding: 20,
                    backgroundColor: '#c05994',
                    width: '100%',
                    borderRadius: 30,
                    fontSize: 14,
                    fontWeight: '600',
                    marginBottom: 20
                  }}>Selesai</ButtonBase>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div style={styles.footerButtons}>

        </div>
      </div>
    )
  }

  goNext() {
    const maxPage = this.state.data.length;
    const currentPage = this.state.currentPage;
    if (currentPage < maxPage - 1) {
      let nextPage = currentPage + 1;
      this.setState({currentPage: nextPage})
    } else {
      this.toLast()
    }
  }

  goPrev() {
    const currentPage = this.state.currentPage;
    if (currentPage > 0) {
      let nextPage = currentPage - 1;
      this.setState({currentPage: nextPage})
    }
  }

  toIndex(index) {
    this.setState({currentPage: index, showIndex: false})
  }

  toLast() {
    this.setState({showIndex: true})
  }

  onPageSelected = e => {
    this.setState({currentPage: e.nativeEvent.position})
  };

  render() {
    return (
      <div style={{
        ...styles.container,
        backgroundColor: '#ab327b',
        padding: 0,
        position: 'relative',
        zIndex: 99999
      }}>
        <PageLoader loading={this.state.loading}/>
        <Container>
          <div className={'flexBox'} style={styles.header}>
            <div style={styles.headerLeft}>
              <div style={{...styles.title, marginBottom: 0, marginTop: 0, textAlign: 'left'}}>{this.state.title}</div>
              <div style={{color: '#ffffff', fontSize: 13}}>
                Soal
                ke {(this.state.currentPage + 1) < this.state.data.length ? this.state.currentPage + 1 : this.state.data.length} dari {this.state.data.length}
              </div>
            </div>
            <Timer
              startDateTime={moment()}
              endDateTime={moment(this.state.startTime).add(this.state.quiz_max_time, 'minutes')}
              textStyle={styles.timerText}
              style={{...styles.timer, textAlign: 'right'}}
              timeEndAction={() => this.validateAnswer(true)}
            />
          </div>
        </Container>
        {this.state.data.length
          ? <div style={{flex: 1, background: '#fff'}}>
            {this.renderItems()}
            {this.renderConfirmation()}
          </div>
          : <div>No question</div>
        }
        <Prompt
          when={!this.state.safeExit}
          message='Anda yakin ingin keluar?'
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizPlayScreen)
